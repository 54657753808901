





































































































import useContact from '@/use/contact'
import useUser from '@/use/user'
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, watch } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

export default defineComponent({
  setup(_, { root }) {
    const { hasAccessTo } = useUser({ root })

    const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance
    const { fetchUnreadFrom } = useContact({ root })

    const items = ref<any[]>([])

    const state = reactive({
      loading: false,
      headers: [
        { value: 'companyMode', text: '', width: 1, sortable: false },
        { value: 'name', text: 'Nazwa firmy' },
        { value: 'city', text: 'Miejscowość' },
        { value: 'postcode', text: 'Kod pocztowy' },
        { value: 'address', text: 'Ulica i numer' },
        { value: 'phone', text: 'Telefon' },
        { value: 'email', text: 'Email' },
        { value: 'action', text: '', width: 1, sortable: false, align: 'right' }
      ],
      items: computed(() => items.value.map((item: any) => ({
        id: item.id,
        name: item.name,
        city: item.city,
        postcode: item.postcode,
        address: item.address,
        phone: item.phone,
        email: item.email,
        NIP: item.NIP,
        REGON: item.Regon,
        KRS: item.KRS,
        users: item.users,
        action: null
      }))),
      options: {
        page: 1,
        itemsPerPage: -1
      },
      contactTimer: null as any
    })

    const model = reactive({
      search: ''
    })

    const unreadFrom = ref<{ id: string; count: number }[]>([])
    const visibleIds = computed<string[]>(() => items.value
      .filter(item => item.users && item.users.length)
      .map(item => item.users.map((user: any) => user.id))
      .flat(1)
    )

    const fetch = () => {
      state.loading = true

      axiosInstance.get('company', { params: { search: model.search || undefined } })
        .then(({ data: { companies } }) => {
          items.value = companies
        })
        .catch(error => console.log(error))
        .finally(() => state.loading = false)
    }

    const fetchUnread = async () => {
      for (const id of visibleIds.value) {
        await fetchUnreadFrom(id)
          .then(({ data: { count } }) => {
            const item = unreadFrom.value.find(i => i.id === id)

            if (item) item.count = count
            else unreadFrom.value.push({ id, count })
          })
          .catch(console.log)
      }
    }

    const getUnreadFrom = (id: string) => {
      if (unreadFrom && unreadFrom.value && unreadFrom.value.find(i => i.id === id)) {
        return unreadFrom.value.find(i => i.id === id)?.count as number
      } else {
        return 0
      }
    }

    const hasUnreadFrom = (id: string) => {
      return getUnreadFrom(id) > 0
    }

    const getUnreadFromMany = (ids: string[]) => {
      let count = 0

      for (const id of ids) {
        if (hasUnreadFrom(id)) count += getUnreadFrom(id)
      }

      return count
    }

    const hasUnreadFromMany = (ids: string[]) => {
      return getUnreadFromMany(ids) > 0
    }

    watch(() => model.search, fetch)
    onMounted(fetch)

    watch(() => visibleIds.value, fetchUnread)
    onMounted(() => state.contactTimer = setInterval(fetchUnread, 5000))
    onBeforeUnmount(() => state.contactTimer ? clearInterval(state.contactTimer) : void 0)

    const deleteCompany = (id: string) => {
      state.loading = true

      axiosInstance.delete(`company/${id}`)
        .then(fetch)
        .catch(error => console.log(error))
        .finally(() => state.loading = false)
    }

    const setCompanyMode = (id: string) => {
      if (id) root.$store.dispatch('companyMode/setCompany', id)
      else root.$store.dispatch('companyMode/removeCompany')
    }
    const getCompanyModeId = computed(() => root.$store.getters['companyMode/getCompanyId'])

    return { hasAccessTo, state, model, deleteCompany, getUnreadFrom, hasUnreadFrom, getUnreadFromMany, hasUnreadFromMany, visibleIds, setCompanyMode, getCompanyModeId }
  }
})
