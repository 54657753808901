var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"icon":"","exact":"","to":{ name: 'panel.dashboard' }}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v("Lista firm")]),_c('v-spacer'),(_vm.hasAccessTo('employee'))?_c('v-btn',{staticClass:"ml-4",attrs:{"depressed":"","dark":"","color":"green","to":{ name: 'panel.company.add' },"exact":""}},[_c('span',{staticClass:"d-none d-md-inline"},[_vm._v("Dodaj firmę")]),_c('span',{staticClass:"d-md-none"},[_vm._v("Dodaj")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","label":"Szukaj","append-icon":"mdi-magnify"},model:{value:(_vm.model.search),callback:function ($$v) {_vm.$set(_vm.model, "search", $$v)},expression:"model.search"}})],1)],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"loading":_vm.state.loading,"headers":_vm.state.headers,"items":_vm.state.items,"options":_vm.state.options,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.companyMode",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","fab":"","x-small":"","color":_vm.getCompanyModeId === item.id ? 'primary' : null},on:{"click":function($event){_vm.getCompanyModeId === item.id ? _vm.setCompanyMode(null) : _vm.setCompanyMode(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.getCompanyModeId === item.id ? 'mdi-close' : 'mdi-monitor-eye'))])],1)]}}],null,true)},[_c('span',[_vm._v("Przełącz tryb firmy")])])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"text-decoration-none",attrs:{"href":("tel:" + (item.phone.replace(' ', '')))}},[_vm._v(_vm._s(item.phone))])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"text-decoration-none",attrs:{"href":("mailto:" + (item.email.replace(' ', '')))}},[_vm._v(_vm._s(item.email))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[(item.users && item.users.length === 1)?_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":"","fab":"","x-small":"","to":{ name: 'panel.contact.service', params: { id: item.users[0].id } }}},[_c('v-icon',[_vm._v("mdi-email")])],1):_vm._e(),(item.users && item.users.length > 1)?_c('v-menu',{attrs:{"left":"","max-height":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"bordered":"","color":"red","offset-x":15,"offset-y":15,"value":_vm.hasUnreadFromMany(item.users.map(function (user) { return user.id; })),"content":_vm.getUnreadFromMany(item.users.map(function (user) { return user.id; }))}},[_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"depressed":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-email")])],1)],1)]}}],null,true)},[_c('v-list',_vm._l((item.users),function(user){return _c('v-list-item',{key:user.id,attrs:{"to":{ name: 'panel.contact.service', params: { id: user.id } }}},[_c('v-list-item-icon',[_c('v-badge',{attrs:{"bordered":"","color":"red","offset-x":15,"offset-y":15,"value":_vm.hasUnreadFrom(user.id),"content":_vm.getUnreadFrom(user.id)}},[_c('v-avatar',{staticClass:"white--text",attrs:{"color":"primary"}},[_vm._v(_vm._s(("" + (user.firstName[0]) + (user.lastName[0]))))])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(user.firstName)+" "+_vm._s(user.lastName))])],1)],1)}),1)],1):_vm._e(),_c('v-menu',{attrs:{"left":"","min-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"depressed":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("NIP")]),_c('v-list-item-title',[(item.NIP)?_c('span',[_vm._v(_vm._s(item.NIP))]):_c('span',[_vm._v("---")])])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("REGON")]),_c('v-list-item-title',[(item.REGON)?_c('span',[_vm._v(_vm._s(item.REGON))]):_c('span',[_vm._v("---")])])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("KRS")]),_c('v-list-item-title',[(item.KRS)?_c('span',[_vm._v(_vm._s(item.KRS))]):_c('span',[_vm._v("---")])])],1)],1)],1)],1),(_vm.hasAccessTo('employee'))?_c('v-menu',{attrs:{"left":"","min-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"depressed":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'panel.company.edit', params: { id: item.id } }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Edytuj")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteCompany(item.id)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"red--text"},[_vm._v("Usuń")])],1),_c('v-list-item-icon',[_c('v-icon',{staticClass:"red--text"},[_vm._v("mdi-delete")])],1)],1)],1)],1):_vm._e()],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }